/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Děti v akci "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ekb0cc --full pb--60 pt--60" name={"3pdsdl4cedu"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/17142/ff475403da0a408785ef15e7431a2867_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --right el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":650}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4 pb--60 pt--60" name={"yiowj1skx1d"} layout={"l1"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold; color: rgb(246, 34, 3);\">Vánoce dětem, kterým osud nepřál.\n</span>"}>
              </Title>

              <Text className="text-box text-box--justify" content={"<span style=\"color: var(--black); font-weight: bold;\">Vánoce se blíží. Ne každý je ale může trávit doma s rodinou a s těmi, které má rád. Sám vím, jaké to je zůstat jako dítě na Vánoce v dětském domově osamocen a právě proto jsem se rozhodl po odchodu z dětského domova dělat každý rok Vánoce dětem v dětských domovech příjemnější, hezčí a s těmi, které mají rádi. \n\n</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5" content={"<span style=\"font-weight: bold;\">VÁNOČNÍ SBÍRKA NA DONIO.CZ</span>"} url={"https://donio.cz/vanoce-detem-kterym-osud-nepral"} href={"https://donio.cz/vanoce-detem-kterym-osud-nepral"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ht0wti6ge5j"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--border4 --center" style={{"paddingBottom":37,"paddingTop":33}} name={"q0leqom2vu"} border={"4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<a href=\"https://ib.fio.cz/ib/transparent?a=2402063206\" target=\"_blank\"><span style=\"font-weight: bold;\">Vánoční účet<br></span>2402063206 / 2010</a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}